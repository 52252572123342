import React from "react"
import PropTypes from "prop-types";
import { Container} from "@ui/wrapper";
import Heading from "@ui/heading";
import { SectionWrap, TestimonialSubTitle, TestimonialText, TranslateBottomBox} from "./style";
const Testimonial = ({data}) => {
    return(
        <SectionWrap id={data.section}>
            <Container>
                    {data?.items?.map((item,index) => (
                        <TranslateBottomBox key={index}>
                        <Heading as={item.type}>
                            {item?.name}
                        </Heading>
                        <TestimonialText>{item?.description}</TestimonialText>
                        <TestimonialSubTitle>
                            {item?.designation}
                        </TestimonialSubTitle>
                        </TranslateBottomBox>
                    ))}
            </Container>
         </SectionWrap>
    );
};
Testimonial.propTypes = {
    items: PropTypes.object,
};
export default Testimonial;