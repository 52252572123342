import styled, { device } from "@styled";
import testimonialsQuote from "@data/images/icons/testimonialsQuote.png";
export const SectionWrap = styled.section`
    padding:10px 0 40px 0;
    ${device.small} {
        padding:15px 0 60px 0;
    }
    ${device.xlarge} {
        padding:20px 0 80px 0;
    }
    .container{
        ${device.xlarge} {
            max-width:1570px;
        }
    }
`;
export const TranslateBottomBox = styled.div`
    text-align: center;
    padding:40px 15px;
    border-radius: 24px;
    background-image: linear-gradient(234deg, #15054f 0%, #521680 100%);
    position:relative;
    z-index:1;
    &::before{
        content:'';
        position:absolute;
        top:30px;
        left:30px;
        width:75px;
        height:75px;
        background-image:url(${testimonialsQuote});
        background-size:contain;
        background-repeat:no-repeat;
        z-index:-1;
        ${device.small} {
            left:40px;
            width:100px;
            height:100px;
        }
        ${device.xlarge} {
            top:45px;
            left:60px;
            width:140px;
            height:110px;
        }
    }
    ${device.small} {
        padding: 60px 25px;
    }
    ${device.large} {
        padding:75px 40px;
    }
    h3{
        font-size: 26px;
        color: #f75019;
        font-weight: bold;
        line-height:1.2;
        margin:0 auto 15px;
        max-width:1165px;
        ${device.small} {
            font-size: 32px;
            margin:0 auto 20px;
        }
        ${device.large} {
            font-size: 36px;
        }
        ${device.xxlarge} {
            font-size: 40px;
            margin:0 auto 30px;
        }
        ${device.xxlarge} {
            font-size: 48px;
            margin:0 auto 40px;
        }
    }  
`
export const TestimonialText = styled.p`
    font-size: 16px;
    line-height: 1.4;
    color: #fefeff;
    margin:0 auto;
    max-width:1165px;
    ${device.small} {
        font-size: 18px;
    }
    ${device.xlarge} {
        font-size: 20px;
    }
    ${device.xxlarge} {
        font-size: 22px;
    }
`
export const TestimonialSubTitle = styled.h5`
    font-size: 14px;
    letter-spacing: 1px;
    color: #e57c2a;
    letter-spacing: 0.25px;
    margin:0 auto;
    max-width:1165px;
    font-weight:400;
    ${device.small} {
        font-size: 16px;
    }
    &::before{
        content:'';
        display:block;
        width: 103px;
        height: 3px;
        background-color: #e57c2a;
        margin:20px auto;
        ${device.small} {
            margin:30px auto;
        }
    }
`